<template>
    <ion-page>
      <ion-content fullscreen>
        <template v-if="viewType == 'list'">

            <List
            ref="board"
            :datas="boardList"
            :total="total"
            :next-loading="disabled"    
            >
            <template  #regDate="{ item }">
                {{ item.mobileName }}
            </template>
            <template  #rightButton="{ item }">
              <template v-if="item.is_confirm == 'Y'">
                <ion-button size="" @click="handleDetail(item)" color="secondary">{{$t('buttonLabelResult')}}</ion-button> 
              </template>
              <template v-else-if="item.is_confirm == 'R' && item.message_code == '0001'">
                <ion-button size="" @click="handleFileReplace(item)" color="tertiary">{{$t('buttonLabelReTakePhoto')}}</ion-button> 
              </template>
              <template v-else-if="item.is_confirm == 'R' && item.message_code == '0002'">
                <ion-button size="" @click="handleMessage(item)" color="danger">{{$t('buttonLabelMessage')}}</ion-button> 
              </template>              
              <template v-else>
                <ion-button size="" color="medium" :disabled="true">{{$t('buttonLabelResult')}}</ion-button> 
              </template>
            </template>
            </List>
            <ion-fab vertical="bottom" horizontal="end" slot="fixed" >
            <ion-fab-button @click="handleRefresh($event)" color="tertiary">
                <ion-icon :icon="reloadOutline"></ion-icon>
            </ion-fab-button>
            </ion-fab>
        </template>

        <template v-else>

            <ion-card class="blue"> 
            <ion-grid>
                <ion-row>
                <ion-col class="title">
                    {{$t('labelModelName')}}
                </ion-col>
                <ion-col class="contents">
                    {{viewData.cate03_name}}
                </ion-col>
                </ion-row>
                <ion-row>
                <ion-col class="title">
                    {{$t('labelImeiName')}}
                </ion-col>
                <ion-col class="contents">
                    {{viewData.imei}}
                </ion-col>
                </ion-row>
                <ion-row>
                <ion-col class="title">
                   Chuong Trinh
                </ion-col>
                <ion-col class="contents">
                    {{viewData.special_info_name}}
                </ion-col>
                </ion-row>            
            </ion-grid>
            </ion-card>

            <ion-card class="orange"> 
            <ion-grid>
                <ion-row>
                <ion-col class="center" v-html="$t('orderSpecialPriceAvailable')">
                </ion-col>
                </ion-row>
                <ion-row>
                <ion-col class="center">
                    Total Value : {{viewData.total_value}} VND
                </ion-col>
                </ion-row>
                <ion-row>
                <ion-col class="right">
                    Grade(Real) : {{ viewData.confirm_grade }}
                </ion-col>
                </ion-row>
            </ion-grid>
            </ion-card>


            <ion-card class="info"> 
             <ion-row>
                <ion-col class="title">
                    {{$t('labelShopCode')}}
                </ion-col>
                <ion-col class="contents">
                    <ion-input :value="shopCode" placeholder="" @ionInput="handleInput('shopCode', $event)" ></ion-input>
                </ion-col>
                <ion-col class="title">
                  {{$t('labelManagerName')}}
                </ion-col>
                <ion-col class="contents">
                    <ion-input :value="managerName" placeholder="" @ionInput="handleInput('managerName', $event)"></ion-input>
                </ion-col>
                <ion-col class="title">
                  {{$t('labelMagagePic')}}
                </ion-col>
                <ion-col class="contents">
                  <template v-if="photo === undefined">
                    <ion-img src="/assets/images/common/no-image.png" @click="handlePhoto()" style="width: 200px;height: 200px;"/>
                  </template>
                  <template v-else> 
                    <ion-img
                      v-if="photo"
                      :src="photo"
                      @ionError="photo = '/assets/images/common/no-image.png'"
                      @click="handlePhoto()"
                      style="width: 200px;height: 200px;"
                      />                            
                  </template>                  
                </ion-col>
                <ion-col class="title">
                  {{$t('labelManagerImei')}}
                </ion-col>
                <ion-col class="contents">
                    <ion-input :value="imei" placeholder="" @ionInput="handleInput('imei', $event)"></ion-input>
                    <ion-button color="secondary" size="small" @click="handlemImeiModal">IMEI SCAN</ion-button>
                </ion-col>            

                <ion-col class="title">
                  {{$t('labelManagerSN')}}
                </ion-col>
                <ion-col class="contents">
                    <ion-input :value="sn" placeholder="" @ionInput="handleInput('sn', $event)"></ion-input>
                    <ion-button color="secondary" size="small" @click="handlemSnModal">S/N SCAN</ion-button>
                </ion-col>       
                </ion-row>
            </ion-card>

             <ion-grid>
                <ion-row>
                <ion-col>
                  <ion-button color="medium" size="large" expand="full" @click="dataCancel">{{$t('labelOrderitemCancel')}}</ion-button>
                </ion-col>
                <ion-col>
                  <ion-button color="secondary" size="large" expand="full" @click="handleModal()">{{$t('labelOrderitemOrder')}}</ion-button>                  
                </ion-col>
                </ion-row>
              </ion-grid>
            
        </template>

        <ion-loading :is-open="isOpenLoading" message="Loading..." spinner="circles"></ion-loading>

        <ion-modal id="modal" :is-open="isOpenModal" @willDismiss="onWillDismiss">

             <ion-grid>
                <ion-row>
                <ion-col >
                  <ion-img src="/assets/images/mobile/img_reset.png" @click="handlePhoto()" style="width: 30%; margin: 0 auto;" />
                </ion-col>
                </ion-row>
                <ion-row>
                <ion-col style="margin-top: 30px;">
                  {{$t('resetText1')}}
                </ion-col>
                </ion-row>              
                <ion-row>
                <ion-col v-html="$t('resetText2')" style="margin-top: 40px; margin-bottom: 60px;">
                </ion-col>
                </ion-row>                
                <ion-row>
                <ion-col >
                  <ion-button color="medium" size="default" expand="full" @click="handleUpdateCheck('N')">{{$t('resetNo')}}</ion-button>
                </ion-col>
                <ion-col >
                  <ion-button color="secondary" size="default" expand="full" @click="handleUpdateCheck('Y')">{{$t('resetYes')}}</ion-button>
                </ion-col>                
                </ion-row>  

              </ion-grid>
        </ion-modal>
        <ion-modal id="barcode" :is-open="isOpenBarcodeModal" @willDismiss="onWillDismiss">
            <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        </ion-modal>
        <ion-modal id="sn" :is-open="isOpenSnBarcodeModal" @willDismiss="onWillDismiss">
            <StreamBarcodeReader @decode="onSnDecode" @loaded="onLoaded"></StreamBarcodeReader>
        </ion-modal>
      </ion-content>
    </ion-page>
  </template>

<script>
import Cookies from 'js-cookie'
import { 
  IonModal,
  IonPage,
  IonContent, 
  IonButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLoading,
  IonInput,
  useIonRouter,
  alertController 
} from '@ionic/vue'
import { StreamBarcodeReader } from "vue-barcode-reader";
import { reloadOutline } from 'ionicons/icons'
import { useRoute } from 'vue-router'
import { defineComponent, ref, onMounted, watch, nextTick} from 'vue'
import List from '@/components/List'
import { listData } from '@/api/board/common'
import { Camera, CameraResultType } from '@capacitor/camera'
import { DataURIToBlobNew } from '@/utils/commonUtils'
import { addData, addFileData, updateData } from '@/api/board/common_v2'
import { useI18n } from 'vue-i18n';


export default defineComponent({
  name: 'mobile-sale-application',
  components: {
    IonModal,
    IonPage,
    IonContent,
    IonButton,
    IonFab,
    IonFabButton,
    IonIcon,    
    List,
    IonInput,
    IonLoading,
    StreamBarcodeReader
  },
  setup() {

    const route = useRoute()
    const router = useIonRouter()

    const { t } = useI18n({ useScope: 'global' });

    const board = ref(null)
    let viewType = ref('list')
    let nextLoading = ref(true)
    let total = ref(0)
    let boardList = ref([])
    let viewData = ref([])
    let isOpenLoading = ref(true)
    let shopCode = ref('')
    let managerName = ref('')
    let imei = ref('')
    let sn = ref('')
    let isOpenModal = ref(false)
    let isOpenBarcodeModal = ref(false)
    let isOpenSnBarcodeModal = ref(false)

    //첨부파일
    let photo = ref(undefined)

    onMounted(async () => {
      //await getList()
    })


    watch(
      () => route.params,
      async () => {

        await nextTick(() => {        
          if(route.path == '/mobile/saleApplication'){
            getList() 
            viewType.value = 'list'
          }
        })
      },
      {
        immediate: true
      }
    )

    //첨부파일 처리
    const handlePhoto = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo.value = image.dataUrl
    }


    const getList = async () => {
      if (nextLoading.value) {
        const memNum = Cookies.get('memNum')   
        const apiUrl = `/api/atm/hardwareinspection/confirm/`+Number(memNum)
        const res = await listData(apiUrl)

        const datas = res.map((o) => {
          return {
            id: o.hardware_inspection_num,
            title: o.ord_number,
            mobileName : o.cate03_name,
            reg_date: o.reg_date,

            /*detail value*/
            add_cmp_amt: o.add_cmp_amt,
            special_yn: o.special_yn,
            atm_display_popup_title: o.atm_display_popup_title,
            ord_number: o.ord_number,
            confirm_grade: o.confirm_grade,
            confirm_price: o.confirm_price,
            imei: o.imei,
            imei2: o.imei2,
            specialPopupTitle: o.specialPopupTitle,
            cart_num: o.cart_num,
            pro_num: o.pro_num,
            special_info_id: o.special_info_id,
            special_info_name: o.special_info_name,
            is_confirm: o.is_confirm,
            message_code: o.message_code,
            message_text: o.message_text,
            cate01_num: o.cate01_num,
            cate03_name: o.cate03_name
          }
        })

        total.value = datas.length
        boardList.value = datas
        isOpenLoading.value = false
      }
    }

    const dataCancel = async () => {
      const apiUrl = 'api/ordercancel'  
      const payload = {
        ord_number: viewData.value.ord_number
      }
      isOpenLoading.value = true
      await updateData(apiUrl, payload).then(response => {
        if(response.status == '200'){
          router.push({
            name: 'mobile-performance',          
            params: {
            }
          })
        }else{
          alert(t('networkError'))
        }
        isOpenLoading.value = false
      })
    }

    const dataOrderUpdate = async () => {

      const apiUrl = 'api/order'

      const item = viewData.value
      const confirm_price = item.confirm_price === undefined || item.confirm_price == '' ? 0 : item.confirm_price 
      const memId = Cookies.get('memId')
      const orderPicture = 'pic.jpg';
    
      //변수 없는건 고정값
      const orderInfo = {
          cate01_num: item.cate01_num,
          mem_id: memId,
          cart_num: item.cart_num,
          ord_number: item.ord_number,
          ord_count: 1,
          imei_check: 'A',
          order_grade: item.confirm_grade,
          standard_price: confirm_price,
          condition_price: confirm_price,
          discount_price: 0,
          pro_num: item.pro_num,
          pre_price: 0,
          corporation_code: '020100',
          savina_shop_code: shopCode.value, //EMPLOY ID
          req_point: 'N',
          order_name: managerName.value,
          order_picture: orderPicture, //img name
          imei_code: imei.value, // 바코드
          is_o2o: 'N', // y , n o2o 가능여부 기능이지만이제 사용안함
          selling_num: 0,
          special_info_id: item.special_info_id,
          sn: sn.value
      }

      //o2o 프로세스가 존재하였으나, 21년후 주석처리되어 사용을 안함
      //om_name, om_email, om_hp, om_hopeprice 이전에는 다음 값만 사용함
      let orderMember = {
          om_name :'',
          om_email :'',
          om_hp :'',
          om_bank_name :'',
          om_acc_name :'',
          om_account :'',
          om_zip :'',
          om_addr1 :'',
          om_addr2 :'',
          om_memo :'',
          om_hopeprice :'',
      }
      
      const payload = {
          orderInfo : orderInfo,
          orderMember : orderMember,
          discountList : {},
          hardware_discountList : {},
      }

      await addData(apiUrl, payload).then(response => {
  
        if(response.status == '200'){
          router.push({
            name: 'mobile-performance',          
            params: {
            }
          })
        }else{
          alert(t('networkError'))
        }
        isOpenLoading.value = false
      })
    }


    const dataFileUpdate = async () => {
      //https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/hardware/${orderNum.value}/pic.jpg
      
      if(photo.value !== undefined){

        const apiUrl = '/api/order/picture';
        let formData = new FormData()
        const file = DataURIToBlobNew(photo.value) 
        const fileOfBlob = new File([file], `pic.jpg`, {type:'image/jpeg'})
        formData.append('file', fileOfBlob)
        formData.append('ord_number', viewData.value.ord_number)
        
        await addFileData(apiUrl, formData).then((response) => {
          if(response.status == '200'){
            return response.data.result   
          }else{
            return 'fail'  
          }
        })
      }else{
        return 'fail'
      }
      
    }

    const dataUpdate = async () => {

      if(managerName.value == '' || shopCode.value == ''){  //|| imei.value == ''
        alert(t('orderRequireInfo'))
        return false
      }

      if(photo.value === undefined){
        alert(t('orderRequireInfo'))
        return false
      }
      
      if(!isIMEI(imei.value) && sn.value === undefined){
        alert(t('orderRequireInfo'))
        return false
      }

      isOpenLoading.value = true
      dataFileUpdate().then((result) => {
        if(result == 'fail'){
          alert(t('networkError') + '[file error]')
          isOpenLoading.value = false
        }else{
          dataOrderUpdate()
        }

      });
        
    }

    //상세 페이지
    const handleDetail = async (item) => {

        viewType.value = 'view' //백엔드에서 list만 제공하여 리스트에서 데이터를 담고 있다 상세 처리
        const add_cmp_amt = item.add_cmp_amt === undefined || item.add_cmp_amt == '' ? 0 : item.add_cmp_amt 

        const totalValue = Number(add_cmp_amt) + Number(item.confirm_price);
        viewData.value = {
            add_cmp_amt: add_cmp_amt,
            special_yn: item.special_yn,
            atm_display_popup_title: item.atm_display_popup_title,
            ord_number: item.ord_number,
            confirm_grade: item.confirm_grade,
            confirm_price: item.confirm_price,
            imei: item.imei, 
            imei2: item.imei2, 
            specialPopupTitle: item.specialPopupTitle,
            cart_num: item.cart_num,
            pro_num: item.pro_num,
            special_info_id: item.special_info_id,
            special_info_name: item.special_info_name,
            is_confirm: item.is_confirm,
            message_code: item.message_code,
            message_text: item.message_text, 
            cate01_num: item.cate01_num,  
            cate03_name: item.cate03_name,
            total_value : String(totalValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }          
    }

      const isIMEI = (s) => {
        const etal = /^[0-9]{15}$/;
        if (!etal.test(s)){
          return false;
        }

        let sum = 0 
        let mul = 2
        let l = 14
        for (let i = 0; i < l; i++) {
          let digit = s.substring(l-i-1,l-i);
          let tp = parseInt(digit,10)*mul;
          if (tp >= 10)
              sum += (tp % 10) +1;
          else
              sum += tp;
          if (mul == 1)
              mul++;
          else
              mul--;
          }
        let chk = ((10 - (sum % 10)) % 10);
        if (chk != parseInt(s.substring(14,15),10)){
          return false;
        }else{
          return true;
        }

      }

    const handleModal = () =>{
      isOpenModal.value = true
    }

    const handleUpdateCheck = async (flag) =>{
      if(flag == 'Y'){
        await dataUpdate()
      } 
      isOpenModal.value = false
    }

    const handleInput = (id, e) => {
      if (id === 'shopCode') {
        shopCode.value = e.target.value
      } else if (id === 'managerName') {
        managerName.value = e.target.value
      } else if (id === 'imei') {
        imei.value = e.target.value
      } else if (id === 'sn') {
        sn.value = e.target.value
      }
    }

    const handleFileReplace = (item) => {
      router.push({
        name: 'mobile-survey-file',          
        params: {
          num: item.ord_number
        },
        query :{
          checklist: item.message_text
        }
      })
    }

    const handleRefresh = () => {
      location.reload()
    }

    const handlemImeiModal = () => {
      isOpenBarcodeModal.value = true
    }

    const handlemSnModal = () => {
      isOpenSnBarcodeModal.value = true
    }
  
    const handleMessage = async(item) =>{
      const newAlert = await alertController.create({
        message: item.message_text,
        buttons: [t('buttonLabelClose')],
      });
      await newAlert.present();

    }

    //바코드 정보
    const onDecode = (result) => {
        imei.value = result 
        isOpenBarcodeModal.value = false
    }

    const onSnDecode = (result) => {
        sn.value = result 
        isOpenSnBarcodeModal.value = false
    }
    
    const onWillDismiss = () => {
      //modal close 감지
      isOpenModal.value = false
      isOpenBarcodeModal.value = false
      isOpenSnBarcodeModal.value = false
    };

    return {
        isOpenModal,
        isOpenBarcodeModal,
        isOpenSnBarcodeModal,
        shopCode,
        managerName,
        imei,
        sn,
        photo,
        isOpenLoading,
        reloadOutline,
        viewType,
        viewData,
        total,
        board,
        boardList,
        getList,
        handleDetail,
        handlePhoto,
        handleInput,
        handleUpdateCheck,
        handleModal,
        handleRefresh,
        handleFileReplace,
        handleMessage,
        handlemImeiModal,
        handlemSnModal,
        onWillDismiss,
        onDecode,
        onSnDecode,
        dataUpdate,
        dataFileUpdate,
        dataCancel,
        dataOrderUpdate
    }
  }
})
</script>

<style scoped>
:deep ion-avatar {
  --border-radius: 2px;
}

:deep ion-item .item-native {
  --padding-start: 0;
}


ion-card.blue{
  margin-top:40px;
  background-color: dodgerblue;
  color:aliceblue;
}
ion-row ion-col.title{
  flex: 0 0 30%;
  text-align: right ;
}
ion-row ion-col.contents{
  flex: 0 0 70%;
  text-align: left ;
  font-weight: bold;
}

ion-card.orange{
  margin-top:40px;
  background-color:coral;
  color:aliceblue;
  text-align: center;
}

ion-card.info{
  margin-top:40px;
}


ion-row ion-col.center{
  text-align: center ;
}

ion-row ion-col.right{
  text-align: right ;
}

ion-input {
  border-bottom-style: solid;
  border-width: 1px;
}

ion-modal#modal {
    --width: fit-content;
    --min-width: 400px;
    --max-width: 80%;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }

ion-modal#barcode {
  --width: fit-content;
  --min-width: 400px;
  --max-width: 80%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#sn {
  --width: fit-content;
  --min-width: 400px;
  --max-width: 80%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-button {
  font-size: 12px;
}

</style>
